import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { isKeyOf } from "@smart/itops-utils-basic";

import { fileTypes } from "./file-types";
import { UploadFileProps, UploadFn, UploadStatus } from "./types";
import { Button } from "../button";
import { Spinner } from "../spinner";

const FileFieldItemWrapper = styled.div`
  --background: rgba(239, 241, 246, 1);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${(props) => props.theme.baseUnit}rem;
  margin: ${(props) => props.theme.baseUnit}rem 0;
  background: var(--background);
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: ${(props) => `${props.theme.baseUnit * 1.4}rem`};

  .file-type {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: ${(props) => props.theme.baseUnit * 2.8}rem;
    height: ${(props) => props.theme.baseUnit * 2.8}rem;
    margin-right: ${(props) => props.theme.baseUnit * 0.3}rem;

    flex: 0 0 ${(props) => props.theme.baseUnit * 2.8}rem;
  }

  .left-pane {
    display: flex;
    flex-flow: column;
    flex: 5;

    .file-details {
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 1rem;
      width: 100%;

      p {
        display: flex;
        margin: 0;
        position: relative;
        font-size: ${(props) => `${props.theme.baseUnit * 1.4}rem`};

        .file-name-part {
          max-width: 40rem;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a:not(:disabled) {
          display: flex;
          color: ${(props) => props.theme.scheme.blue.r100};
          text-decoration: underline;
        }
      }
    }

    .progress {
      margin: 0.5rem 0 0 0.5rem;
      height: ${(props) => props.theme.baseUnit * 0.5}rem;
      gap: 0.5rem;

      background: rgba(223, 227, 236, 1);
      border-radius: 1rem;
      overflow: hidden;

      .bar {
        height: 100%;
        background: rgba(33, 176, 202, 1);
        opacity: 0.6;

        transition: width 0.2s ease;
      }
    }
  }

  .right-pane {
    display: flex;
    justify-content: flex-end;
    flex: 3;
    max-width: 100%;

    .error {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.scheme.red.r100};

      .exclamation {
        margin-left: 0.2rem;
      }
    }

    button {
      margin: 0;
      background: var(--background);
      color: ${(props) => props.theme.scheme.grey.r400};
    }
  }
`;

export type FileFieldItemProps = {
  fileName: string;
  downloadUrl: string | undefined;
  uploadUrl: string | undefined;
  file: File | undefined;
  uploadStatus: UploadStatus;
  upload: UploadFn;
  onStatusChange: (uploadStatus: UploadStatus) => void;
  onRemove: () => void;
  uploadFileProps: UploadFileProps;
  fileSizeLimitMegaByte: number;
  maxNumOfFiles: number;
  fileIndex: number;
  fieldId: string;
};

const knownFileType = isKeyOf(fileTypes);
const fileNameToIcon = (name: string) => {
  const extension = name.split(".").reverse()[0];
  return knownFileType(extension) ? fileTypes[extension] : fileTypes.txt;
};

export const FileFieldItem = ({
  fileName,
  downloadUrl,
  uploadUrl,
  file,
  uploadStatus,
  upload,
  onStatusChange,
  onRemove,
  fileSizeLimitMegaByte,
  maxNumOfFiles,
  fileIndex,
  fieldId: id,
  uploadFileProps: {
    onProgress,
    onUploadStatusChange,
    initialise,
    getUploadStates,
  },
}: FileFieldItemProps) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadStates = getUploadStates(id);
  const notUploadedStatuses: UploadStatus[] = [
    "failedToUpload",
    "exceedSizeLimit",
    "unsupportedFileType",
  ];
  const failedUploadReason: Partial<Record<UploadStatus, string>> = {
    exceedSizeLimit: `File exceeds the ${fileSizeLimitMegaByte} MB limit. Please upload a smaller file and try again.`,
    unsupportedFileType:
      "Unsupported file format. Please upload a valid file type.",
    failedToUpload: "Upload failed. Please try again.",
    fileEncrypted: "Cannot upload a password protected file.",
  };

  const extractFileDetails = () => {
    const parts = fileName.split(".");
    const extension = parts.pop();

    return {
      name: parts.join("."),
      extension: `.${extension}`,
    };
  };

  const { name: namePart, extension } = extractFileDetails();

  useEffect(() => {
    if (uploadStates.length > 0) {
      setUploading(uploadStates[fileIndex].uploading);
      setProgress(uploadStates[fileIndex].progress);
    } else {
      initialise(id, maxNumOfFiles);
    }

    if (progress === 100 && uploadStatus === "uploading") {
      onUploadStatusChange(id, fileIndex, false);
      onStatusChange("uploaded");
    }
  }, [uploadStates, progress, uploading]);

  useEffect(() => {
    if (uploadStatus === "notUploaded" && uploadUrl && file && !uploading) {
      onUploadStatusChange(id, fileIndex, true);
      onStatusChange("uploading");
      setUploading(true);

      upload({
        uploadUrl,
        file,
        onProgress: (newProgress) => {
          onProgress(id, fileIndex, newProgress);
          setProgress(newProgress);
        },
      })
        .then(() => {
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("uploaded");
          setUploading(false);
        })
        .catch((e) => {
          console.error(e);
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("failedToUpload");
          setUploading(false);
        });
    }
  }, [uploadStatus, uploadUrl]);

  return (
    <FileFieldItemWrapper className="file-field-item">
      <div className="left-pane">
        <div className="file-details">
          {uploading || uploadStatus === "notUploaded" ? (
            <Spinner size={3} />
          ) : (
            <div
              className="file-type"
              style={{ backgroundImage: `url(${fileNameToIcon(fileName)})` }}
            />
          )}
          <p>
            {uploading ||
            !downloadUrl ||
            notUploadedStatuses.includes(uploadStatus) ? (
              <>
                <span className="file-name-part">{namePart}</span>
                <span>{extension}</span>
              </>
            ) : (
              <a
                href={downloadUrl}
                target="_blank"
                rel="noreferrer"
                aria-label={fileName}
              >
                <span className="file-name-part">{namePart}</span>
                <span>{extension}</span>
              </a>
            )}
          </p>
        </div>
        {uploading && (
          <div
            className="progress"
            role="progressbar"
            aria-label="Upload Progress"
            aria-valuenow={progress}
          >
            <div className="bar" style={{ width: `${progress}%` }} />
          </div>
        )}
      </div>
      <div className="right-pane">
        {failedUploadReason[uploadStatus] && (
          <div className="error">
            <span>{failedUploadReason[uploadStatus]}</span>
            <div className="exclamation">
              <Icon
                library="lucide"
                name="AlertCircle"
                color="rgba(230, 12, 25, 1)"
              />
            </div>
          </div>
        )}
        <Button
          icon={{ library: "lucide", name: "X" }}
          kind="borderless"
          title="Remove File"
          variant="plain"
          onClick={onRemove}
          disabled={uploadStatus === "uploading" || uploading}
        />
      </div>
    </FileFieldItemWrapper>
  );
};
