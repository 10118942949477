const specialCharsRegex = /[\x7F-\x9F\u200B-\u200F\u2020A-\u202E\u206F\uFEFF]/;

export const specialChars = {
  nbsp: "\u00A0",
  enDash: "\u2013",
  infinity: "\u221e",
  ellipsis: "\u2026",
  invisible: "\u200b",
};

export const removeSpecialChars = (text: string): string =>
  text.replace(specialCharsRegex, "");
