import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";
import {
  formatDate,
  jsonParseOrReturn,
  specialChars,
} from "@smart/itops-utils-basic";

import { SmokeballAddress } from "./address";
import { system } from "./base";
import { FieldNS, FieldType } from "./field";
import { UploadedFile } from "./file";
import { PhoneNumberResponse } from "./phone-number-response";
import { SubmissionNS } from "./submission";
import { CurrencyResponse } from "./currency-response";
import { AppointmentResponse } from "./appointment";
import { PaymentResponse } from "./payment-response";

export type ResponseValue =
  | null
  | number
  | string
  | string[]
  | PhoneNumberResponse
  | CurrencyResponse
  | SmokeballAddress
  | UploadedFile[]
  | AppointmentResponse
  | PaymentResponse;

export type OptionType = { value: string; label: string };

export const handleArrayOptionValues = (
  value: string[],
  options?: OptionType[] | null,
) => {
  return value
    .map((v) => options?.find((o) => o.value === v)?.label || v)
    .join(", ");
};

export const responseSyncStatusValue = [
  "synced",
  "notSynced",
  "syncing",
  "failedToSync",
  "loading",
  "loaded",
  "failedToLoad",
  "pendingContactReview",
  "notToSync",
  "reviewed",
] as const;

export const responseSyncStatusSchema = z
  .enum(responseSyncStatusValue)
  .optional();

export type ResponseSyncStatus = (typeof responseSyncStatusValue)[number];

export const ResponseNS = buildNamespace({
  system,
  entity: "Response",
} as const);

export const ResponseSchema = z.object({
  uri: ResponseNS.schema,
  operationId: z.string(),

  submissionUri: SubmissionNS.schema,
  fieldUri: FieldNS.schema,
  value: z.string(),
  updatedAt: z.string(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),

  syncStatus: responseSyncStatusSchema,
  existingItemIds: z.string().optional(),
});

const isOfType = <T>(
  predictedType: FieldType | FieldType[],
  type: FieldType,
  value: unknown,
): value is T => {
  if (Array.isArray(predictedType)) {
    return predictedType.includes(type);
  }

  return type === predictedType;
};

export const formatValue = (
  value: ResponseValue | undefined,
  type: FieldType,
  options?: OptionType[] | null,
): string => {
  if (value === undefined || value === null) return "";

  if (isOfType<string | undefined>("date", type, value))
    return (
      formatDate(value, { allowUndefined: true, parseAsLocal: true }) || ""
    );

  if (isOfType<SmokeballAddress | undefined>("address", type, value))
    return value?.formattedAddress || "";

  if (isOfType<PhoneNumberResponse | undefined>("phoneNumber", type, value))
    return value?.formattedNumber || "";

  if (isOfType<CurrencyResponse | undefined>("currency", type, value))
    return value?.formatted || "";

  if (isOfType<UploadedFile[] | undefined>("file", type, value))
    return Array.isArray(value)
      ? value.map((file) => file.fileName).join(", ") || ""
      : (value as Omit<UploadedFile, "uploadStatus">)?.fileName || "";

  if (isOfType<string | undefined>(["choice", "yesNo"], type, value))
    return (
      options?.find((o) => o.value === value)?.label ||
      (typeof value !== "object" && !Array.isArray(value) ? value || "" : "")
    );

  if (isOfType<string[] | undefined>("checkbox", type, value))
    return (
      (Array.isArray(value)
        ? handleArrayOptionValues(value, options)
        : value) || ""
    );

  if (isOfType("multilineText", type, value))
    return value.toString()?.replace(/\n/g, "\n\n");

  if (typeof value === "string" || typeof value === "number")
    return String(value);

  return JSON.stringify(value);
};

export const formatJsonStringValue = (
  jsonString: string | undefined,
  type: FieldType,
  options?: OptionType[],
): string | undefined => {
  const value = jsonParseOrReturn(jsonString);
  return formatValue(value, type, options);
};

export const checkHasResponse = (
  type: FieldType,
  value: ResponseValue | undefined,
) => {
  switch (type) {
    case "number":
      return typeof value === "number" && !Number.isNaN(value);
    case "checkbox":
      return !!(value as string[])?.[0];
    case "address":
      return !!(value as SmokeballAddress)?.formattedAddress;
    case "phoneNumber":
      return !!(value as PhoneNumberResponse)?.formattedNumber;
    case "currency":
      return !!(value as CurrencyResponse)?.formatted;
    case "file":
      return !!(value as UploadedFile[])?.some(
        ({ uploadStatus }) => uploadStatus === "uploaded",
      );
    case "appointment": {
      const appointment = value as AppointmentResponse | undefined;
      return (
        !!appointment?.bookedStaff &&
        !!appointment?.startTime &&
        !!appointment?.endTime &&
        !!appointment?.status
      );
    }
    case "payment":
      return !!(value as PaymentResponse).chargeRequest;
    default:
      return !!value;
  }
};
