import { delay } from "./delay";
import { isTestEnv } from "./env";
import { FetchError, logError } from "./errors";
import { isNotNullOrUndefined } from "./guard";
import { Guard, jsonParse } from "./validation";

export const fetchWithRetry = async (
  input: string,
  {
    retry,
    backoff,
    ...options
  }: RequestInit & { retry?: number; backoff?: number },
) => {
  let attempt = 1;
  let result = await fetch(input, options);

  if (!isTestEnv()) {
    while (!result.ok && attempt <= (retry || 0)) {
      const delayed = (backoff || 500) * attempt;
      await delay(delayed);

      attempt += 1;
      result = await fetch(input, {
        ...options,
        headers: {
          attempt: `${attempt}`,
          ...options.headers,
        },
      });
    }
  }

  return result;
};

type AuthOptions =
  | { type: "basic"; username: string; password: string }
  | { type: "bearer"; token: string }
  | { type: "loader"; load: () => Promise<string> }
  | { type: "token"; token: string };

const buildAuthHeader = async (opts: AuthOptions) => {
  switch (opts.type) {
    case "basic":
      return `Basic ${Buffer.from(`${opts.username}:${opts.password}`).toString(
        "base64",
      )}`;
    case "bearer":
      return `Bearer ${opts.token}`;
    case "loader":
      return opts.load();
    case "token":
      return `Token ${opts.token}`;
    default:
      return "";
  }
};

export type GuardedFetchOptions = {
  urlPrefix?: string;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  auth?: AuthOptions;
  headers?: Record<string, string>;
  searchParams?: Record<string, string | number | boolean | undefined>;
  body?: FormData | URLSearchParams;
  json?: Record<string, any>;
  skipParsing?: boolean;
};

export const guardedFetch = async <E>(
  url: string,
  options: GuardedFetchOptions,
  guard: Guard<E>,
  action: string,
) => {
  try {
    let statusCode;
    let input;
    let body;
    try {
      const headers = new Headers({
        ...options.headers,
      });

      if (options.auth)
        headers.set("Authorization", await buildAuthHeader(options.auth));

      const fullUrl = new URL(url, options.urlPrefix);
      if (options.searchParams) {
        Object.entries(options.searchParams || {}).forEach(([key, value]) => {
          if (isNotNullOrUndefined(value)) {
            fullUrl.searchParams.set(key, value.toString());
          }
        });
      }

      if (options.body instanceof URLSearchParams) {
        headers.set("Content-Type", "application/x-www-form-urlencoded");
        input = options.body.toString();
      } else if (options.body instanceof FormData) {
        input = options.body;
      } else if (options.json) {
        headers.set("Content-Type", "application/json; charset=utf-8");
        input = JSON.stringify(options.json);
      }

      const fetchOptions = {
        headers,
        method: options.method,
        body: input,
      };
      if (process.env.DEBUG)
        console.info(`Fetching ${fullUrl}`, {
          method: fetchOptions.method,
          body: fetchOptions.body,
          headers: Object.fromEntries(fetchOptions.headers.entries()),
        });

      const fetched = await fetch(fullUrl, fetchOptions);
      statusCode = fetched.status;
      body = await fetched.text();
    } catch (e) {
      throw new FetchError(e, { action });
    }

    if (statusCode >= 400) {
      throw new FetchError(
        {
          code: statusCode,
          message: "Unsuccessful Request",
          request: { requestUrl: url },
          options: { body: input },
          response: { statusCode, body },
        },
        { action },
      );
    }

    if (options.skipParsing) return body as E;

    const parsed = await jsonParse(body, `${action} parse`);
    const guarded = await guard(parsed, `${action} guard`);

    return guarded;
  } catch (e) {
    logError(e, { url, options });
    throw e;
  }
};

export type GuardedFetch = typeof guardedFetch;

export const guardedFetchFactory =
  (baseOptions: GuardedFetchOptions) =>
  async <E>(
    url: string,
    options: GuardedFetchOptions,
    guard: Guard<E>,
    action: string,
  ) =>
    guardedFetch(
      url,
      {
        ...baseOptions,
        ...options,
        headers: { ...baseOptions.headers, ...options.headers },
        searchParams: { ...baseOptions.searchParams, ...options.searchParams },
      },
      guard,
      action,
    );
