import { css } from "@emotion/react";

import poppinsBlack from "./Poppins-Black.woff2";
import poppinsBlackItalic from "./Poppins-BlackItalic.woff2";
import poppinsBold from "./Poppins-Bold.woff2";
import poppinsBoldItalic from "./Poppins-BoldItalic.woff2";
import poppinsMedium from "./Poppins-Medium.woff2";
import poppinsMediumItalic from "./Poppins-MediumItalic.woff2";
import poppinsSemiBold from "./Poppins-SemiBold.woff2";
import poppinsSemiBoldItalic from "./Poppins-SemiBoldItalic.woff2";
import { fontFaces } from "../util";

const fontFamily = `Poppins`;

export const poppinsStack = `${fontFamily}, Helvetica, Arial, sans-serif`;

export const poppins = css`
  ${fontFaces({
    family: fontFamily,
    format: "woff2",
    formats: [
      { style: "normal", weight: "normal", path: poppinsMedium },
      { style: "italic", weight: "normal", path: poppinsMediumItalic },
      { style: "normal", weight: "600", path: poppinsSemiBold },
      { style: "italic", weight: "600", path: poppinsSemiBoldItalic },
      { style: "normal", weight: "bold", path: poppinsBold },
      { style: "italic", weight: "bold", path: poppinsBoldItalic },
      { style: "normal", weight: "900", path: poppinsBlack },
      { style: "italic", weight: "900", path: poppinsBlackItalic },
    ],
  })}
`;
